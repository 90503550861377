import styled from 'styled-components'

const FooterContainerLinks = styled.div`
  flex: 60%;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  /* border: 2px solid blue; */
  @media (max-width: 480px) {
    flex: 50%;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0rem;
  }
`

export default FooterContainerLinks