import React, { useState } from 'react'
import Button from '../button/button'

import addToMailchimp from 'gatsby-plugin-mailchimp'

import styled from 'styled-components'
import { ButtonType } from '../button'
import { colors } from '../../../utils/styles/styles'

const StyledEmailSubscription = styled.div`
  div.container__header {
    h5 {
      margin-bottom: 0px;
    }
    span.wow {
      font-size: 10px;
    }
  }
  form {
    position: relative;
    width: 100%;
    margin-top: 1rem;
    display: flex;
    input, button {
      height: 36px;
    }
    input {
      /* box-sizing: border-box; */
      width: 200px;
      padding: 0rem 0.8rem;
      font-size: 16px;
      border: none;
      &:focus {
        box-shadow: 0 0 14px rgba(215, 173, 12, 0.45);
      }
    }
    button.newsletter {
      padding: 0 1.4rem;
      margin: 0px;
      border-radius: 0px;
      line-height: 100%;
    }
    div.status {
      position: absolute;
      bottom: -105px;
      width: 100%;
      height: auto;
      font-size: 10px;
      background: yellow;
      color: #000;
    }
  }
  @media (max-width: 480px) {
    width: 100%;
    div.container__header {
      width: 100%;
    }
    form {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
`

const EmailSubscription = () => {
  const [emailInput, setEmailInput] = useState('');
  const [messageStatus, setMessageStatus] = useState('');

  const handleChange = (e) => {
    setEmailInput(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await addToMailchimp(emailInput);
      console.log(response)
      setMessageStatus(response.msg)
    } catch (error) {
      alert(error);
    }
  }

  return (
    <StyledEmailSubscription>
      <div className="container__header">
        <h5>Subscribe to our Newsletter</h5>
        <span className="wow">We promise to not send spam!</span>
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          onChange={handleChange}
          placeholder="my-email@email.com"
        />
        <ButtonType.Base className="newsletter" id="footer__newsletter-submit">
          Submit
        </ButtonType.Base>
        { messageStatus !== '' && <div className="status">{messageStatus}</div> }
      </form>
      {/* <div className="message">
        Subscribed! Check your inbox for details.
      </div> */}
    </StyledEmailSubscription>
  )
}

export default EmailSubscription
