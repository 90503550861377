import Axios from "axios"

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("user")
    ? JSON.parse(window.localStorage.getItem("user"))
    : {}

const setUser = user =>
  window.localStorage.setItem("user", JSON.stringify(user))

export const handleLogin = ({ username, password }) => {
  if (username === `john` && password === `pass`) {
    return setUser({
      username: `john`,
      name: `Johnny`,
      email: `johnny@example.org`,
    })
  }

  return false
}

export const handleUserLogin = async ({ username, password }) => {
  const headers = {
    "Content-Type": "application/json"
  }
  let res = await Axios.post('https://api.leadmirror.com/api/user/login/', { username, password });
}

export const isLoggedIn = () => {
  const user = getUser()

  return !!(user.username || user.email)
}

export const logout = callback => {
  setUser({})
  callback()
}