import styled from 'styled-components'
import { colors, fonts } from '../../../../utils/styles/styles'

const BaseButton = styled.button`
  cursor: pointer;
  padding: 12px 30px;
  display: flex;
  align-items: center;
  background: ${props => {
    if(props.variant === 'primary') {
      return colors.pri
    }
    if(props.variant === 'secondary') {
      return colors.sec
    }
    if(props.variant === 'plain') {
      return '#fff'
    }
  }};
  color: ${props => {
    if(props.variant === 'primary') {
      return colors.white
    }
    if(props.variant === 'secondary') {
      return colors.white
    }
    if(props.variant === 'plain') {
      return '#222'
    }
  }};
  border: 2px solid ${props => {
    if(props.variant === 'primary') {
      return colors.pri
    }
    if(props.variant === 'secondary') {
      return colors.sec
    }
    if(props.variant === 'plain') {
      return '#222'
    }
  }};
  border-radius: 30px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);

  font-family: ${fonts.pri};
  font-size: 14px;
  font-weight: 700; 

  line-height: 100%;
  text-decoration: none;
  transition: 0.4s all;
  /* SVG for Icon */
  svg {
    margin-left: 10px;
    transition: 0.4s all;
    path {
      fill: ${props => {
        if(props.variant === 'primary') {
          return colors.white
        }
        if(props.variant === 'secondary') {
          return colors.black
        }
      }};
    }
  }
  &:hover {
    transition: 0.8s all;
    background: ${props => {
      if(props.variant === 'primary') {
        return colors.priDark
      }
      if(props.variant === 'secondary') {
        return colors.secDark
      }
    }};
    border: 2px solid ${props => {
      if(props.variant === 'primary') {
        return colors.priDark
      }
      if(props.variant === 'secondary') {
        return colors.secDark
      }
    }};
    box-shadow: 0px 0px 20px ${props => {
      if(props.variant === 'primary') {
        return colors.priGlow;
      }
      if(props.variant === 'secondary') {
        return colors.secGlow;
      }
    }};
    text-decoration: none;
    svg {
      transform: translateX(4px);
      transition: 0.4s all;
    }
  }
`

BaseButton.defaultProps = {
  variant: 'primary'
}

export default BaseButton