import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import styled from "styled-components"
import { dimensions, colors } from "../../../utils/styles/styles"
import Row from "../row/row"
import FooterContainerBadges from "./footer-components/footer-container-badges/footer-container-badges"
import FooterContainerLinks from "./footer-components/footer-container-links/footer-container-links"
import FooterColumn from "./footer-components/footer-container-links/footer-column"
import FooterSocial from "./footer-components/footer-social/footer-social"
import EmailSubscription from "../subscription/email-subscription"

const StyledFooter = styled.footer`
  position: static;
  bottom: 0px;
  width: 100%;
  margin: 0rem 0rem 0rem;
  padding: 4rem 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.backgroundAccent};
  .producthunt-badge {
    margin-bottom: 20px;
  }
  .credits {
    display: flex;
    justify-content: center;
    span {
      display: inline-block;
      font-size: 10px;
      text-align: center;
      color: ${props => props.theme.foreground};
      a {
        display: inline-block;
        font-weight: bold;
        color: ${props =>
          props.theme.name === "dark" ? colors.sec : colors.secDark};
      }
    }
  }
  @media (max-width: 480px) {
    padding: 3rem 2rem 7.5rem;
    .credits {
      flex-direction: row;
    }
  }
`
const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterInfo {
      site {
        siteMetadata {
          footerMenuLinks {
            footerHeader
            footerLinks {
              name
              link
              target
            }
          }
        }
      }
    }
  `)
  const { footerMenuLinks } = data.site.siteMetadata
  return (
    <StyledFooter>
      <Row fullwidth={false} isCentered={true}>
        <FooterContainerLinks>
          {footerMenuLinks.map((column, index) => {
            return (
              <FooterColumn
                key={index}
                columnTitle={column.footerHeader}
                columnLinks={column.footerLinks}
              />
            )
          })}
        </FooterContainerLinks>
        <FooterContainerBadges>
          <a
            href="https://www.producthunt.com/posts/leadmirror-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-leadmirror-2"
            target="_blank"
            className="producthunt-badge"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=175343&theme=dark"
              alt="LeadMirror - All-in-one SEO tool with keyword ranker, backlink analysis | Product Hunt Embed"
            />
          </a>
          <EmailSubscription />
        </FooterContainerBadges>
      </Row>
      <Row width={dimensions.siteWidth}>
        <FooterSocial />
      </Row>
      <Row width="100%" className="credits">
        <span>
          Built with ☕ and ❤️ at{" "}
          <a
            href="https://montaigne.co/?ref=leadmirror.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Montaigne Smart Business Solutions
          </a>{" "}
          | © {new Date().getFullYear()} | All Rights Reserved
        </span>
      </Row>
    </StyledFooter>
  )
}

export default Footer
