import React from 'react'
import styled from 'styled-components'
import { colors, dimensions } from '../../../utils/styles/styles'
import CountDownTimer from '../count-down-timer/count-down-timer'

const StyledBanner = styled.aside`
  box-sizing: border-box;
  position: fixed;
  top: 0px;
  z-index: 999;
  width: 100%;
  height: ${dimensions.bannerHeight};
  div.container__inner {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(32, 223, 100, 0.85);
    span.inner_content {
      color: ${colors.white};
      font-size: 14px;
      text-align: center;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }
    a {
      display: inline;
      color: inherit;
      font: inherit;
      font-weight: bold;
      text-decoration: underline;
      &:hover {
        color: ${colors.sec};
      }
    }
  }
  @media (max-width: 480px) {
    position: static;
    height: auto;
    display: none;
    div.container__inner {
      padding: 8px 6px;
      span.inner_content {
        font-size: 12px;
      }
      a {
        padding: 8px 0px 0px;
        display: block;
      }
    }
  }
`

const Banner = ({
  children
}) => {
  return (
    <StyledBanner>
      <div className="container__inner">
        {children}
      </div>
    </StyledBanner>
  )
}

Banner.defaultProps = {

}

export default Banner