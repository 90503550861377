import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { dimensions, colors } from '../../../../utils/styles/styles'

const StyledBlogNavbar = styled.div`
  position: relative;
  z-index: 89;
  top: ${dimensions.bannerHeight};
  ul {
    width: 100%;
    margin: 0px;
    padding: 8px 6px;
    display: flex;
    justify-content: center;
    background: ${colors.secGradient};
    li {
      margin: 0px;
      font-size: 12px;
      text-align: center;
      list-style: none;
      &:not(:last-child) {
        margin-right: 30px;
      }
      a {
        color: #ddd;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`

const BlogNavbar = (props) => {
  const data = useStaticQuery(graphql`
    query allCategoriesForBlogMenu {
      allWordpressCategory(filter: {slug: {ne: "uncategorized"}, parent_element: {name: {eq: null}}, acf: {in_blog_navbar: {eq: "yes"}}}) {
        edges {
          node {
            name
            slug
            parent_element {
              name
            }
            acf {
              in_blog_navbar
            }
          }
        }
      }
    }   
  `)
  const { edges } = data.allWordpressCategory;
  return (
    <StyledBlogNavbar>
      <ul>
        {
          edges.map((edge, index) => {
            const { name, slug } = edge.node;
            return (
              <li key={index} >
                <Link to={`/a2i-world/category/${slug}`}>
                  #{name}
                </Link>
              </li>
            )
          })
        }
      </ul>
    </StyledBlogNavbar>
  )
}

BlogNavbar.defaultProps = {

}

export default BlogNavbar