import React, { useContext } from "react"
import styled, { ThemeContext, css } from "styled-components"
import NavbarLinks from "./navbar-links/navbar-links"
import NavbarLogo from "./navbar-logo/navbar-logo"
import { useStaticQuery, graphql, Link } from "gatsby"
import useScrollPosition from "../../../hooks/useScrollPosition"
import Banner from "../../banner/banner"
import { dimensions } from "../../../../utils/styles/styles"

const StyledLargeScreen = styled.nav`
  position: sticky;
  top: ${dimensions.bannerHeight};
  z-index: 1011;
  height: 70px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.foreground};
  background: ${props => props.theme.background};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  @media (max-width: 768px) {
    display: none;
  }
`

const LargeScreen = props => {
  const theme = useContext(ThemeContext)
  
  const data = useStaticQuery(graphql`
    query AllNavbarLinks {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
            target
          }
        }
      }
    }
  `)
  const { menuLinks } = data.site.siteMetadata
  return (
    <>
      <Banner>
        <span className="inner_content">
          A tale of me <span>👨‍⚕️</span> and COVID-19 : Inside the mind of a Startup CEO - <Link to="/a2i-world/a-tale-of-me-and-covid-19-inside-the-mind-of-a-startup-ceo/">Know More!</Link>
        </span>
      </Banner>
      <StyledLargeScreen theme={theme}>
        <NavbarLogo theme={theme} />
        <NavbarLinks menuLinks={menuLinks} />
      </StyledLargeScreen>
    </>
  )
}

LargeScreen.defaultProps = {}

export default LargeScreen
