import { createGlobalStyle, ThemeContext } from 'styled-components'
import { fonts, colors } from '../../../utils/styles/styles'
import { useContext } from 'react'

// const theme = useContext(ThemeContext);

const GlobalStyle = createGlobalStyle`
  
  html {
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${props => props.theme.foreGround};
    background: ${props => props.theme.background};
    margin: 0px;
    padding: 0px;
    font-family: ${fonts.sec};
  }

  body > * ::selection {
    color: #000;
    background-color: #F2C94C;
  }

  ::-webkit-scrollbar {
    width: 10px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: ${colors.priGradient};
    border-radius: 10px;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${props => props.theme.headingColor};
    font-family: ${fonts.pri};
    line-height: 133%;
  }

  h1 { font-size: 5.4rem; }
  h2 { font-size: 4.8rem; }
  h3 { font-size: 3.6rem; }
  h4 { font-size: 2.4rem; }
  h5 { font-size: 2.0rem; }
  h6 { font-size: 1.6rem; }

  p, span, li, ul {
    font-family: ${fonts.sec};
    color: ${props => props.theme.bodyColor};
    font-size: 1.8rem;
    font-weight: 400;
  }

  a {
    color: ${colors.white};
    text-decoration: none;
    &:hover {
      color: ${colors.white};
    }
  }

  img {
    margin: 0px;
  }

  button {
    border: none;
  }

  @media (max-width: 768px) {
    h1 { font-size: 3.6rem; }
    h2 { font-size: 2.4rem; }
    h3 { font-size: 2.0rem; }
    h4 { font-size: 1.8rem; }
    h5 { font-size: 1.8rem; }
    h6 { font-size: 1.6rem; }
  }

`

export default GlobalStyle;