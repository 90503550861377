import React from 'react'
import styled from 'styled-components'

import Home from '../../../../../assets/navbar/Home.inline.svg'
import Features from '../../../../../assets/navbar/Features.inline.svg'
import Pricing from '../../../../../assets/navbar/Pricing.inline.svg'
import Blog from '../../../../../assets/navbar/Blog.inline.svg'
import Seo from '../../../../../assets/navbar/Seo.inline.svg'
import { Link, useStaticQuery, graphql  } from 'gatsby'
import useWindowSize from '../../../../hooks/useWindowSize'


const StyledBottomMenu = styled.div`
  position: fixed;
  z-index: 1122;
  bottom: 0px;
  width: 100vw;
  padding: 14px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.background};
  div.menu__item {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: ${props => props.theme.foreground};
      }
    }
    span {
      padding-top: 2px;
      font-size: 8px;
      color: ${props => props.theme.foreground};
    }
  }
`

const BottomMenu = ({ theme }) => {
  return (
    <StyledBottomMenu
      theme={theme}
    >
      <Link to="/">
        <div className="menu__item">
          <Home />
          <span>Home</span>
        </div>
      </Link>
      <Link to="/features">
        <div className="menu__item">
          <Features />
          <span>Features</span>
        </div>
      </Link>
      <Link to="/pricing">
        <div className="menu__item">
          <Pricing />
          <span>Pricing</span>
        </div>
      </Link>
      <Link to="/a2i-world">
        <div className="menu__item">
          <Blog />
          <span>A2I World</span>
        </div>
      </Link>
      <Link to="/tool/seo-report">
        <div className="menu__item">
          <Seo />
          <span>SEO Tool</span>
        </div>
      </Link>
    </StyledBottomMenu>
  )
}

BottomMenu.defaultProps = {

}

export default BottomMenu