import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import LightLogo from '../../../../../images/lm-logo-full-dark.png'
import DarkLogo from '../../../../../images/lm-logo-full.png'


const StyledNavbarLogo = styled.div`
  flex: 10%;
  display: flex;
  align-items: center;
  a {
    color: #fff;
    img {
      height: 36px;
      width: auto;
    }
  }
`

const NavbarLogo = ({ theme }) => {
  return (
    <StyledNavbarLogo>
      <Link to="/">
        {
          theme.name === 'dark' ? (
            <img src={DarkLogo} alt="logo"/>
          ) : (
            <img src={LightLogo} alt="logo"/>
          )
        }
      </Link>
    </StyledNavbarLogo>
  )
}

NavbarLogo.defaultProps = {

}

export default NavbarLogo