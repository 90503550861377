import React from 'react'
import styled from 'styled-components'
import { ButtonType } from '../../../button'
import { Link } from 'gatsby'
import { colors } from '../../../../../utils/styles/styles'
import { isLoggedIn } from '../../../../../utils/api/auth'

const StyledNavbarLinks = styled.div`
  color: inherit;
  ul {
    margin: 0px;
    display: flex;
    align-items: center;
    color: inherit;
    li {
      margin: 0px;
      color: inherit;
      list-style: none;
      font-size: 16px;
      a {
        color: inherit;
      }
      button {
        padding: 10px 18px;
        font-size: 14px;
      }
      &:hover {
        color: ${colors.priDark};
        text-decoration: underline;
      }
      &:not(:last-child) {
        padding-right: 30px;
      }
    }
  }
  .activeMenuItem {
    color: ${colors.pri};
    font-weight: bold;
    cursor: default;
  }
`

const NavbarLinks = ({ menuLinks }) => {
  return (
    <StyledNavbarLinks>
      <ul>
        {
          menuLinks.map((menuItem, index) => {
            if (menuItem.target === 'internal') {
              return (
                <li key={index}>
                  <Link to={`/${menuItem.link}`} activeClassName="activeMenuItem">
                    {menuItem.name}
                  </Link>
                </li>
              )
            } else {
              return (
                <li>
                  <a href={`/${menuItem.link}`} target="_blank" rel="noopener noreferrer">
                    {menuItem.name}
                  </a>
                </li>
              )
            }
          })
        }
        <li>
          <a href="https://app.leadmirror.com" target="_blank" rel="noopener noreferrer">
            <ButtonType.Base variant="secondary" id="navbar__signup">
              {
                isLoggedIn() ? 'Go to App' : 'Sign Up / Login'
              }
            </ButtonType.Base>
          </a>
        </li>
        {/* <li>
          <a href="https://app.leadmirror.com" target="_blank" rel="noopener noreferrer">
            <ButtonType.Ghost id="navbar__signup">
              Sign Up
            </ButtonType.Ghost>
          </a>
        </li> */}
      </ul>
    </StyledNavbarLinks>
  )
}

NavbarLinks.defaultProps = {

}

export default NavbarLinks