import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import { GlobalContext } from "../../../context/global-context"
import Footer from "../footer/footer"
import { NavbarType } from "../navbar-responsive"
import BlogNavbar from "../navbar/blog-navbar/blog-navbar"
import GlobalStyle from "./globalStyle"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const StyledLayout = styled.div`
  position: relative;
`

const Layout = ({ children, isBlogMenuActive, isFooterShown, theme }) => {

  // Ctx
  const { state, dispatch } = useContext(GlobalContext);

  // Component Did mount
  useEffect(() => {
    dispatch({ type: 'CHANGE_THEME', payload: theme })
  }, [])

  return (
    <>
      <StyledLayout>
        {/* Navbar */}
        <NavbarType.SmallScreen />
        <NavbarType.LargeScreen />
        {isBlogMenuActive ? <BlogNavbar /> : null}

        {/* Main */}
        <main>{children}</main>
        {isFooterShown ? <Footer /> : null}
      </StyledLayout>
      <GlobalStyle />
    </>
  )
}

Layout.defaultProps = {
  theme: "dark",
  isBlogMenuActive: false,
  isFooterShown: true,
}

export default Layout
