import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import BottomMenu from "./bottom-menu/bottom-menu"
import TopMenu from "./top-menu/top-menu"

const StyledSmallScreen = styled.div`
  position: absolute;
  width: 0px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 768px) {
    display: none;
  }
`

const SmallScreen = () => {
  const theme = useContext(ThemeContext)
  return (
    <StyledSmallScreen>
      <TopMenu theme={theme} />
      <BottomMenu theme={theme} />
    </StyledSmallScreen>
  )
}

SmallScreen.defaultProps = {}

export default SmallScreen
