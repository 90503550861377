import React from 'react'
import styled from 'styled-components'

import LightLogo from '../../../../../images/lm-logo-full-dark.png'
import DarkLogo from '../../../../../images/lm-logo-full.png'
import { Link } from 'gatsby'

const StyledTopMenu = styled.div`
  position: relative;
  z-index: 1122;
  width: 100vw;
  background: ${props => props.theme.background};
  div.container__inner {
    width: 100%;
    padding: 14px 16px;
    display: flex;
    justify-content: center;
    img {
      height: 36px;
    }
  }
`

const TopMenu = ({ theme }) => {
  return (
    <StyledTopMenu
      theme={theme}
    >
      <Link to="/">
        <div className="container__inner">
          {
            theme.background === '#000' ? (
              <img src={DarkLogo} alt="Dark Logo" />
            ) : (
              <img src={LightLogo} alt="Light Logo" />
            )
          }
        </div>
      </Link>
    </StyledTopMenu>
  )
}

TopMenu.defaultProps = {

}

export default TopMenu