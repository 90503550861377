import React from 'react'
import styled from 'styled-components'
import Countdown from 'react-countdown-now';

const StyledCountDownTimer = styled.div`
  display: inline;
  span {
    width: 100px;
    display: inline-block;
    padding: 2px 4px;
    border-radius: 20px;
    background: yellow;
    color: #000;
    font-size: 14px;
    font-weight: bold;
    line-height: 100%;
  }
`

const CountDownTimer = ({ children }) => {
  return (
    <StyledCountDownTimer>
      <Countdown 
        date={820800000 + 1574318021213} 
        zeroPadTime={2}
        zeroPadDays={2}
      />
    </StyledCountDownTimer>
  )
}

CountDownTimer.defaultProps = {

}

export default CountDownTimer