import styled from 'styled-components'

const FooterContainerBadges = styled.div`
  /* flex: 40%; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* border: 2px solid blue; */
  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
  }
  @media (max-width: 480px) {
    align-items: flex-start;
  }
`

export default FooterContainerBadges