import styled from 'styled-components'

const Column = styled.div`
  padding: ${props => props.padding};
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.isCentered ? 'center' : 'flex-start'};
  border: ${props => props.border};
`
Column.defaultProps = {
  isCentered: false,
  padding: '0px',
  className: ''
}

export default Column