import React from 'react'
import styled from 'styled-components'
import BaseButton from '../base/base'
import { colors } from '../../../../utils/styles/styles'

const GhostButton = styled(BaseButton)`
  background: transparent;
  color: ${props => {
    if(props.variant === 'primary') {
      return colors.pri
    }
    if(props.variant === 'secondary') {
      return colors.sec
    }
    else if(props.variant === 'default') {
      return props.theme.foreground
    }
  }};
  border: 2px solid ${props => {
    if(props.variant === 'primary') {
      return colors.pri
    }
    if(props.variant === 'secondary') {
      return colors.sec
    }
    else if(props.variant === 'default') {
      return props.theme.foreground;
    }
  }};
`
GhostButton.defaultProps = {
  test: 'yellow',
  variant: 'default'
}

export default GhostButton