import React from 'react'
import ButtonRightArrow from '../../../assets/button/button_arrow.inline.svg'

import styled from 'styled-components'

const IconContainer = styled.span`
  display: inline-block;
  padding: 0px;
  svg {
    path {
      stroke: ${props => props.color};
    }
  }
`

const Icon = (props) => {

  return (
    <IconContainer
      {...props}
    >
      <ButtonRightArrow />
    </IconContainer>
  )
}

Icon.defaultProps = {
  color: '#fff'
}

export default Icon
