import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { colors } from '../../../../../utils/styles/styles'

const StyledFooterColumn = styled.div`
  flex: 33.33%;
  margin: 0 20px;
  ul {
    margin-left: 0px;
  }
  @media (max-width: 480px) {
    flex: auto;
    margin: 0px;
    margin-bottom: 30px;
  }
`

const FooterColumnLink = styled.li`
  margin: 4px 0px;
  font-size: 12px;
  list-style-type: none;
  a {
    color: ${props => props.theme.bodyColor};
    text-decoration: none;
    &:hover {
      color: ${colors.priDark};
    }
  }
`

const FooterColumn = ({ columnTitle, columnLinks }) => {
  return (
    <StyledFooterColumn>
      <h5>{columnTitle}</h5>
      <ul>
        {
          columnLinks.map((item, index) => {
            return (
              <FooterColumnLink key={index}>
                {
                  item.target === 'internal' ? (
                    <Link to={`/${item.link}`}>
                      {item.name}
                    </Link>
                  ) : (
                    <a href={`${item.link}`} target="_blank" rel="noopener noreferrer">
                      {item.name}
                    </a>
                  )
                }
              </FooterColumnLink>
            )
          })
        }
      </ul>
    </StyledFooterColumn>
  )
}

FooterColumn.defaultProps = {
  columnTitle: '',
  columnLinks: [
    {
      name: 'Hello',
      link: '/'
    }
  ]
}

export default FooterColumn
