import styled from 'styled-components'
import { dimensions } from '../../../utils/styles/styles'
import Column from '../column/column'

const Row = styled.div`
  width: 100%;
  max-width: ${props => props.fullwidth ? '100%' : dimensions.siteWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.isCentered ? 'center' : 'flex-start'};
  border: ${props => props.border};
  div:first-child {
    margin-left: 0px;
  }
  div:last-child {
    margin-right: 0px;
  }
  ${Column} {
    margin: 0 ${props => props.gap};
    width: ${props => `${100/(props.columns)}%`};
  }
  @media (max-width: 768px) {
    flex-direction: column;
    ${Column} {
      width: 100%;
      margin: 20px 0px;
    }
  }
`

Row.defaultProps = {
  columns: 1,
  gap: '20px',
  isCentered: true,
  height: 'auto',
  fullwidth: true,
  width: dimensions.siteWidth,
  border: 'none'
}

export default Row;