import React from 'react'
import styled from 'styled-components'

// Svg Icons
import Facebook from '../../../../../assets/social/Facebook.inline.svg'
import Twitter from '../../../../../assets/social/Twitter.inline.svg'
import Instagram from '../../../../../assets/social/Instagram.inline.svg'
import Linkedin from '../../../../../assets/social/Linkedin.inline.svg'
import Youtube from '../../../../../assets/social/Youtube.inline.svg'
// import Kiik from '../../../../../assets'


const StyledFooterSocial = styled.div`
  width: 100%;
  margin: 2rem 0rem 2rem;
  display: flex;
  justify-content: center;
  div.container__social {
    width: 100%;
    max-width: 380px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      svg path {
        fill: ${props => props.theme.foreground}
      }
    }
  }
`

const FooterSocial = () => {
  return (
    <StyledFooterSocial>
      <div className="container__social">
        <a href="https://www.facebook.com/Lead-Mirror-2258431834442363/  " target="_blank" rel="noopener noreferrer">
          <Facebook />
        </a>
        <a href="https://twitter.com/LeadMirror" target="_blank" rel="noopener noreferrer">
          <Twitter />
        </a>
        <a href="https://www.instagram.com/lead_mirror/ " target="_blank" rel="noopener noreferrer">
          <Instagram />
        </a>
        <a href="https://www.linkedin.com/company/leadmirror/" target="_blank" rel="noopener noreferrer">
          <Linkedin />
        </a>
        <a href="https://www.youtube.com/channel/UCx-rE13y-l5TuLPFvsVNHBA" target="_blank" rel="noopener noreferrer">
          <Youtube />
        </a>
      </div>
    </StyledFooterSocial>
  )
}

export default FooterSocial
