import React from 'react'

import styled from 'styled-components'
import { colors, fonts } from '../../../utils/styles/styles.js'
import Icon from '../icon/icon.jsx';

const ButtonContainer = styled.button`
  cursor: pointer;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  font-family: ${fonts.sec};
  font-size: 14px;
  line-height: 0%;
  border: none;
  border-radius: 40px;
  background: ${props => {
    if (props.variant === 'primary') {
      return colors.pri
    }
    if (props.variant === 'primaryDark') {
      return colors.priDark
    }
    if (props.variant === 'primaryGradient') {
      return colors.priDark
    }
    if (props.variant === 'secondary') {
      return colors.sec
    }
    if (props.variant === 'secondaryDark') {
      return colors.secDark
    }
    if (props.variant === 'secondary-gradient') {
      return colors.secGradient
    }
  }};
  color: ${props => props.textColor};
  font-family: ${fonts.sec}, sans-serif;
  transition: 0.6s all;
  span.content {
    display: inline-block;
    color: inherit;
    font: inherit;
  }
  &:hover {
    background: ${props => {
      if (props.variant === 'primary') {
        return colors.priDark
      }
      if (props.variant === 'primaryDark') {
        return colors.pri
      }
      if (props.variant === 'primaryGradient') {
        return colors.priDark
      }
      if (props.variant === 'secondary') {
        return colors.secDark
      }
      if (props.variant === 'secondaryDark') {
        return colors.sec
      }
      if (props.variant === 'secondary-gradient') {
        return colors.secGradient
      }
    }};
    transition: 0.6s background ease-in;
  }
`;

const Seperator = styled.span`
  height: auto;
  margin: 0px 8px 0px 10px;
  display: inline-block;
  color: ${props => props.textColor};
  font-size: 20px;
  font-weight: bold;
  line-height: 0%;
`

const Button = (props) => {
  return (
    <React.Fragment>
      <ButtonContainer
        {...props}
      >
        <span className="content">{props.children}</span>
        <Seperator
          textColor={props.textColor}
        >
          |
        </Seperator>
        <Icon 
          color={props.textColor}
        />
      </ButtonContainer>
    </React.Fragment>
  )
}

export default Button

Button.defaultProps = {
  variant: 'primary',
  size: 'medium',
  textColor: '#fff',
}

Seperator.defaultProps = {
  
}



